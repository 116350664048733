import React, {useState, useEffect, useCallback} from 'react';

import { Container,  Grid} from '@/modulos/ModulosMaterialUi';



import './Confirmacion.css';
import {Button} from "@material-ui/core";
import STATIC_API from "@/components/assets/tudecides.json";
import {animateScroll, scrollSpy} from "react-scroll";

/*function useQuery() {
    return new URLSearchParams(useLocation().search);
}*/

const Final = () => {

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        scrollSpy.update();
        scrollToTop();
    }, []);

    const scrollToTop = useCallback(() => {
        animateScroll.scrollToTop();
    }, []);


    return !isLoading ? (
            <>
                <Container className="final__proceso" maxWidth={"md"}>
                    <Grid item xs={12}>
                        <h2>Hoja de información complementaria</h2>
                        <p style={{fontSize: "16px"}}>
                            Este documento tiene el objeto de ampliar la información recibida al aceptar la
                            participación en este estudio, en relación con su naturaleza experimental. Esta
                            información complementaria se proporciona ahora, después de que haya
                            visualizado los productos y contestado los cuestionarios, siguiendo
                            rigurosamente el método científico, para evitar sesgos y lograr así una mayor
                            validez de las conclusiones extraídas. Además de conocer su percepción y su
                            intención de compra de alimentos y bebidas destinados a sus hijos, el estudio
                            tiene por objeto conocer la influencia en los mismos de un sistema de
                            etiquetado nutricional frontal interpretativo (semáforo nutricional Nutri-Score) y
                            de las alegaciones nutricionales o de salud.</p>

                        <p><b>A tal efecto, usted ha sido
                            asignado al azar a uno de los siguientes grupos:</b></p>

                        <ul>
                            <li>
                                <b>1)</b> grupo control: los productos
                                alimentarios solo contenían la tabla con la información nutricional obligatoria,
                                pero no Nutri-Score ni alegaciones
                            </li>
                            <li>
                                <b>2)</b> grupo N-S: los productos contenían la
                                tabla de composición nutricional y el semáforo nutricional Nutri-Score
                            </li>
                            <li>
                                <b>3)</b> grupo
                                alegaciones: los productos contenían la tabla de composición nutricional y una
                                o varias alegaciones nutricionales o de salud o
                            </li>
                            <li>
                                <b>4)</b> grupo combinado: los
                                productos contenían la tabla de composición nutricional, el Nutri-Score y una o
                                varias alegaciones.
                            </li>

                        </ul>

                        <p style={{fontSize: "16px"}}>
                            Una vez que todos los participantes hayan completado el experimento,
                            compararemos las respuestas de los participantes asignados a cada uno de los
                            cuatro grupos. Los resultados del estudio se harán públicos en la misma página
                            web donde se aloja el experimento y los podrá consultar siguiendo el siguiente
                            enlace: <a href={"https://hestiaxxi.com/resultados/  "}>Resultados estudio</a></p>
                        <p style={{fontSize: "16px"}}>
                            Por último, <b>muchas gracias</b> por participar en el estudio y tomarse la molestia y
                            el tiempo necesario para visualizar los productos, completar los cuestionarios y
                            leer este documento. Con su participación está contribuyendo a mejorar el
                            estado del conocimiento en el campo de la nutrición y la salud pública y a
                            diseñar políticas alimentarias para mejorar la salud de la población.</p>

                    </Grid>
                    <Grid item xs={12} >
                        <a className={'mt-2 enlace__hestia'} href={'https://hestiaxxi.com'}>Volver a la web de HestiaXXI</a>
                    </Grid>
                </Container>
            </>
        ) :
        (
            'Cargando ...'
        );
}

export default Final;
