import React, { useState, useEffect } from 'react';

const useFormYup = (callback, validate,valoresDefecto) => {

  const [values, setValues] = useState(valoresDefecto);
  const [errors, setErrors] = useState([]);
  const [errores,setErrores] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clases,setClases] = useState([]);

  useEffect(() => {
    // errors es una promesa, no un array,
    Promise.resolve(errors).then(function(errores) {

      if (errores.length === 0 && isSubmitting) {
        setErrores(errores) // lo ponemos aquí también para que borre los errores.
        callback();
      } else {
        setErrores(errores)
        let clases = [];

        setClases(clases)
      }
    })

  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    setValues,
    errores,
    clases
  }
};

export default useFormYup;
