import React from 'react';

import Container from '@material-ui/core/Container';
import './PieApp.css';

function PieApp(props) {

    return (
        <Container maxWidth={"md"}>

            <div className="pie__informacion">
                <ul className="menu" id="menu-menu_legal">
                </ul>
            </div>

        </Container>
    );
}



export default React.memo(PieApp);
