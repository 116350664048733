import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import Header from './components/Header';
import PieApp from "./PieApp";

import './App.css';
import Consentimiento from "@/components/Consentimiento";
import Formulario from "@/components/Formulario";
import Final from "@/components/Final";
import Productos from "@/components/Productos";

const App = () => {
    let domain = window.location.hostname;
    let baseRouter = '/';

    if (domain === "hestia.test" ) {
        baseRouter = '/app' ;
    }
    console.log(import.meta.env.VITE_SOME_KEY)

    return (
        <div className="evoratec__app">
            <Header/>
            <div className="evoratec__container">
                <Router basename={baseRouter}>
                    <Routes>
                        <Route exact path="/" element={ <Consentimiento />}/>
                        <Route exact path="/productos" element={ <Productos />}/>
                        <Route exact path="/formulario" element={ <Formulario />}/>
                        <Route exact path="/final" element={ <Final />}/>
                    </Routes>
                </Router>
            </div>
            <PieApp></PieApp>
        </div>
    )
}
export default App;
