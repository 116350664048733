import {createSlice} from '@reduxjs/toolkit';
import jwtService from '@/services/jwtService';

export const grabarUrl = ({url,datos}) =>
    async (dispatch) => {
        dispatch(setGrabando(true))
        return jwtService.evoApi(url, datos)
            .then((data) => {
                dispatch(setGrabando(false))
                if (data.status) {

                }
                return data;
            })
            .catch((errors) => {
                dispatch(setGrabando(false))
                return errors;
            });
    };

const initialState = {
    consentimiento: 0,
    codigoUsuario : '',
    productos :[],
    tipo_estudio : 1,
    codigo_participante : "",
    grabando: "",
    tipo_notacion : "N"  // Nutriscore / Chilenos

};

const tudecidesSlice = createSlice({
    name: 'tudecides',
    initialState,
    reducers: {
        setDatos: (state, action) => {
            state[action.payload.variable] = action.payload.data;
        },

        setTipoEstudio: (state, action) => {
            state.tipo_estudio = action.payload;
        },
        setConsentimiento: (state, action) => {
            state.consentimiento = action.payload;
        },
        setProductosStore: (state, action) => {
            state.productos = action.payload;
        },
        setCodigoPartificante: (state, action) => {
            state.codigo_participante = action.payload;
        },
        setGrabando: (state, action) => {
            state.grabando = action.payload;
        },
    },

});

export const {setDatos,setCodigoPartificante,setTipoEstudio,setConsentimiento, setProductosStore,setGrabando} = tudecidesSlice.actions;

export default tudecidesSlice.reducer;
