import { configureStore, Tuple } from '@reduxjs/toolkit'
import {thunk} from 'redux-thunk';

import { rootReducer } from "./reducers/rootReducer";


function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);

        window.localStorage.setItem('hestiaXXI', serializedState);
    } catch(e) {
        console.log(e)
    }
}
const loadState = (CurrVersion) => {
    try {
        if (CurrVersion !== localStorage.getItem('appVersion')){
            localStorage.removeItem('state') //
            localStorage.setItem('appVersion', CurrVersion)
            return undefined;
        }
        else{
            const serializedState = localStorage.getItem('hestiaXXI')
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState)
        }
    } catch (err) {
        return undefined
    }
}

const CurrVersion = 'v1.0';
const persistedState = loadState(CurrVersion);

const store = configureStore({
    preloadedState:persistedState,
    reducer: rootReducer,
    middleware: () => new Tuple(thunk),
})
store.subscribe( () => saveToLocalStorage((store.getState())));
export default store;
