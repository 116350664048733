import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Container, Grid} from '@/modulos/ModulosMaterialUi';

import Checkbox from '@material-ui/core/Checkbox';
import * as yup from "yup";
import useFormYup from "./useFormYup";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import './Confirmacion.css';

import {grabarUrl, setCodigoPartificante, setConsentimiento, setTipoEstudio} from "@/store/reducers/tudecidesSlice";

const SignupSchema = yup.object().shape({
    aceptacondiciones: yup.bool().oneOf([true], 'Debe de otorgar el consentimiento')
});

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const TextoConsentimiento = () => {
    return (

        <div className="">
            <h2 data-fontsize={40} style={{lineHeight: '1.2'}} data-lineheight="48px"
                className="fusion-responsive-typography-calculated">Hoja de información y consentimiento informado – Tú
                decides</h2>
          {/*  <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">Estudio sobre los hábitos de consumo de alimentos y
                bebidas procesadas</h3>*/}
            <p>En primer lugar, muchas gracias por considerar la participación en el estudio y tomarse la molestia y el
                tiempo necesario de leer este documento. Antes de aceptar su participación, lea atentamente los
                siguientes apartados. </p>
            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">¿Cuál es el motivo del estudio?</h3>
            <p>Queremos conocer las opiniones de los padres sobre determinados alimentos y
                bebidas dirigidos niños.</p>
            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">¿En qué consiste el estudio?</h3>
            <p>El estudio consiste en visualizar cuatro productos alimentarios dirigidos a niños
                y contestar un breve cuestionario tras la visualización de cada uno de ellos.
                El cuestionario plantea cuestiones acerca de la percepción de cada uno de los
                productos mostrados, la frecuencia de consumo considerada adecuada y su
                intención de compra.</p>

            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">Beneficios y riesgos derivados de la participación
                en el estudio</h3>
            <ul>
                <li><strong><span className="destacado">Beneficios</span></strong>: contribuirá a generar conocimiento científico dirigido a
                    promover la salud en relación con los hábitos alimentarios y a diseñar
                    estrategias para que la información nutricional sea más clara para el
                    consumidor.
                </li>
                <br/>
                <li><strong><span className="destacado">Riesgos</span></strong>: No existen ningún riesgo derivado de la
                    participación en el estudio.
                </li>
            </ul>
            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">Calidad científica y requerimientos éticos del
                estudio</h3>
            <p>Este estudio ha sido aprobado por el Comité de Ética de Investigación del Instituto de Salud Carlos III,
                que vigila para que la investigación que se hace con personas se haga de acuerdo con la declaración de
                Helsinki, el Convenio del Consejo de Europa sobre derechos humanos y biomedicina y la ley 14/2007, de
                investigación biomédica.</p>
            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">Participación voluntaria y retirada del estudio</h3>
            <p>La participación en este estudio es voluntaria y usted puede decidir su no participación o la retirada en
                cualquier momento. Si usted decide retirarse del estudio una vez comenzado, podrá elegir borrar todos
                los datos de los ficheros informáticos o permitir el análisis de los datos disponibles.</p>
            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">Confidencialidad</h3>
            <p>En este estudio no se recoge ninguna información personal identificativa de los participantes, por lo que
                todos los datos del estudio son anónimos, de modo que nadie, ni siquiera el personal investigador, podrá
                proceder a la identificación de los mismos en ningún momento.</p>
            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">Compensación</h3>
            No hay ninguna compensación establecida por su participación en este estudio. Usted podrá consultar los
            resultados agregados del estudio, una vez que estén disponibles, en el <a target="_blank"
                                                                                      href="https://hestiaxxi.com/resultados/">apartado
            correspondiente de esta página web</a>.
            <h3 data-fontsize={32} style={{lineHeight: '1.3'}} data-lineheight="41.6px"
                className="fusion-responsive-typography-calculated">Contacto</h3>
            <p>Si tienen alguna duda sobre algún aspecto del estudio o le gustaría comentar o ampliar cualquier
                información, por favor consulte en cualquier momento con el investigador principal del estudio, cuyos
                datos se indican a continuación:</p><br/>
            <p><b>Nombre</b>: Miguel Ángel Royo Bordonada</p>
            <p><b>Correo electrónico:</b> mroyo@isciii.es</p>
            <p><b>Teléfono:</b> 918222274 - 918222492</p><br/>
            <p>Una vez que haya leído esta información y haya aclarado sus dudas, si desean participar en el estudio
                deberán clicar a continuación para confirmar su aceptación:</p>


            <span className={'consentimiento_titulo'}>Consentimiento (Obligatorio)</span>
            <div className="texto_consentimeinto">Otorgo mi consentimiento de manera
                voluntaria para participar en el estudio y para el acceso y utilización de los datos en las
                condiciones detalladas en la hoja de información. Sé que soy libre de retirarme en cualquier momento
                del estudio, por cualquier razón y sin tener que dar explicaciones.
            </div>


        </div>);
}


const Consentimiento = () => {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const consentimiento = useSelector((state) => state.tudecides.consentimiento, shallowEqual);


    if (consentimiento === true) {
        navigate('/productos');
    }

    const valoresDefecto = {
        aceptacondiciones: false
    };
    const RenderErrors = props => {
        if (errores.length > 0) {
            return (
                <div className={"errores__datos"}>
                    {errores.map((error, key) => {
                        if (error.field === props.campo) {
                            return (
                                <span className="error__datos" key={key}>
                                    {error.message}
                                </span>
                            )
                        }
                    })}
                </div>
            );
        }
        if (errores.length === 0 || !errores) {
            return null
        }
    };

    function randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const enviar = () => {

        const codigoParticiante = generateString(6)
        const tipoEstudio = randomIntFromInterval(1, 4)

        dispatch(setTipoEstudio(tipoEstudio))
        dispatch(setConsentimiento(1))
        dispatch(setCodigoPartificante(codigoParticiante))


        // añadir grabación del estudio en la api.
        let parametros = {
            url: '/crear',
            datos: {codigo_participante: codigoParticiante, tipo_estudio: tipoEstudio}
        }
        dispatch(grabarUrl(parametros)).then((response) => {
            if (response.status) {
                navigate("/productos");
            }

        });

    };


    const validate = async values => {
        let errors = [];
        try {
            SignupSchema.validateSync(values, {abortEarly: false});
            return errors;
        } catch (errors) {
            const schemaErrors = errors.inner.map(err => {
                return {field: err.path, message: err.message};
            });

            return schemaErrors;
        }
    };


    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        errores,
        clases
    } = useFormYup(enviar, validate, valoresDefecto);


// esto es para el check de confirmación
    const inputCheck = (event) => {
        setValues({...values, [event.target.name]: event.target.checked});
    };
    return !isLoading ? (<>
        <Container className="consentimiento" maxWidth={"md"}>
            <TextoConsentimiento/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <p></p>
                    <FormControlLabel
                        control={<Checkbox checked={values.aceptacondiciones} onChange={inputCheck}
                                           name="aceptacondiciones"/>}
                        label="Si"
                    />
                    <RenderErrors campo="aceptacondiciones"></RenderErrors>
                </Grid>
            </Grid>

            <div className="checkout-form-btn-section">
                <Button variant="contained" color="primary" onClick={handleSubmit} className={`btn-enabled`}>
                    Aceptar
                </Button>
            </div>
        </Container>

    </>) : ('Cargando ...');
}

export default Consentimiento;
