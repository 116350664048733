import Evoutils from '../../utils/EvoUtils';
import axios from 'axios';


let domain = window.location.hostname;

let urlApi = 'http://hestia.test/api' ;
urlApi = 'https://api.hestiaxxi.com/api' ;
if (domain === "tudecides.hestiaxxi.com" ) {
  urlApi = 'https://api.hestiaxxi.com/api' ;
}
class JwtService extends Evoutils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
    this.actualizaToken();
  }
  // Intercetamos tadas las llamadas. Si alguna de ellas nos da 401, hacemos un logout
  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 ) {
            this.setSession(null);
            this.emit('onAutoLogout', 'Invalid access_token');
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    // leemos el token
    const access_token = this.getAccessToken();
    // si no hay token emitimos un evento
    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  evoApi = (url,data) => {
    return new Promise((resolve, reject) => {
      let token = window.localStorage.getItem('access_token');
      axios.post(urlApi+url, data).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      }).catch( errors => reject(errors) );
    });
  };

  evoApiPdf = (url,data) => {
    return new Promise((resolve, reject) => {
      axios.post(urlApi+url, data).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  evoApiGet = (url,data) => {
    return new Promise((resolve, reject) => {
      axios.get(urlApi+url, data).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(urlApi+'/users/registro', data).then((response) => {
          this.setSession(response.data.data.token);
          resolve(response.data.data);
      }).catch((error) => {
        reject(error.response);
      });
    });
  };

  signInWithEmailAndPassword = (usuario, password) => {
    return new Promise((resolve, reject) => {
      axios.post(urlApi+'/login', {
        usuario,
        password,
      })
          .then((response) => {
            if (response.data) {
              // En caso de que no de error guardamos el token en LocalStorage.
              this.setSession(response.data.data.token);
              resolve(response.data.data);
            }
          }).catch((error) => {
            reject(error.response);
      });
    });
  };

  verifyToken = () => {
    return new Promise((resolve, reject) => {
      axios.get(urlApi+'/me', {})
          .then((response) => {
              resolve(response.data);
          }).catch((errors) => {
            reject(errors);
        });
    });
  };

  updateUserData = (user) => {
    return axios.post('/auth/user/update', {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      // Guardamos el token
      localStorage.setItem('access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    return true;
  };

  actualizaToken = () => {
    let access_token = window.localStorage.getItem('access_token');
    this.setSession(access_token)
  }

  getAccessToken = () => {
    return window.localStorage.getItem('access_token');

  };
}

const instance = new JwtService();


export default instance;
