import React, {useState, useEffect, useRef} from 'react';

import {Button, Grid, TextField, Radio, RadioGroup, Typography} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as yup from "yup";
import useFormYup from "./useFormYup";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Container from "@material-ui/core/Container";
import {useNavigate} from "react-router-dom";

import './Formulario.css'
import {grabarUrl} from "@/store/reducers/tudecidesSlice";

const SignupSchema = yup.object().shape({
    nacionalidad: yup.number().oneOf([1, 2], 'Introduzca nacionalidad'),
    sexo: yup.number().oneOf([1, 2], 'Introduzca sexo'),
    edad: yup.number().min(16, "Edad entre 16 y 99").max(99, "Edad entre 16 y 99"),
    numero_hijos: yup.number().min(1, "Número de hijos entre 1 y 12").max(15, "Número de hijos entre 1 y 12"),
    nivel_estudios: yup.number().oneOf([1, 2, 3, 4], 'Introduzca nivel educativo'),
    etapa_academica: yup.number().oneOf([1, 2, 3, 4], 'Etapa académica de los hijos'),
    composicion_hogar: yup.number().oneOf([1, 2], 'Introduzca composición del hogar'),
    ingresos_economicos: yup.number().oneOf([1, 2, 3, 4], 'Introduzca ingresos economicos'),
    consulta_informacion: yup.number().oneOf([1, 2, 3, 4, 5], 'Introduzca si observas la información nutricional'),
    conoces_nutriscore: yup.number().oneOf([1, 2, 3, 4, 5], 'Introduzca si conoces NutriScore'),
});


const Formulario = (props) => {
    const dispatch = useDispatch();
    const codigo_participante = useSelector((state) => state.tudecides.codigo_participante, shallowEqual);
    const productosStore = useSelector((state) => state.tudecides.productos, shallowEqual);
    const tipoEstudio = useSelector((state) => state.tudecides.tipo_estudio, shallowEqual);


    const RenderErrors = props => {
        if (errores.length > 0) {
            return (
                <div className={"errores__datos"}>
                    {errores.map((error, key) => {
                        if (error.field === props.campo) {
                            return (
                                <span className="error__datos" key={key}>
                                    {error.message}
                                </span>
                            )
                        }
                    })}
                </div>
            );
        }
        if (errores.length === 0 || !errores) {
            return null
        }
    };

    const hayError = (campo) => {
        let clase = ''
        errores.map((error) => {
            if (error.field === campo) {
                clase = 'error'
            }
        })

        return clase;
    }

    const validate = async values => {
        let errors = [];
        try {
            SignupSchema.validateSync(values, {abortEarly: false});
            return errors;
        } catch (errors) {
            const schemaErrors = errors.inner.map(err => {
                return {field: err.path, message: err.message};
            });

            return schemaErrors;
        }
    };


    const valoresDefecto = {

        sexo: 0,
        edad: 0,
        numero_hijos: 0,
        nacionalidad: 0,
        nivel_estudios: 0,
        etapa_academica: 0,
        composicion_hogar: 0,
        ingresos_economicos: 0,
        consulta_informacion: 0,
        conoces_nutriscore: 0

    };
    const enviar = () => {


        let datosProductos = {
            prod01_resp01: productosStore[0].cuestiones.cuestion_1,
            prod01_resp02: productosStore[0].cuestiones.cuestion_2,
            prod01_resp03: productosStore[0].cuestiones.cuestion_3,

            prod02_resp01: productosStore[1].cuestiones.cuestion_1,
            prod02_resp02: productosStore[1].cuestiones.cuestion_2,
            prod02_resp03: productosStore[1].cuestiones.cuestion_3,

            prod03_resp01: productosStore[2].cuestiones.cuestion_1,
            prod03_resp02: productosStore[2].cuestiones.cuestion_2,
            prod03_resp03: productosStore[2].cuestiones.cuestion_3,

            prod04_resp01: productosStore[3].cuestiones.cuestion_1,
            prod04_resp02: productosStore[3].cuestiones.cuestion_2,
            prod04_resp03: productosStore[3].cuestiones.cuestion_3,

        };


        // añadir grabación del estudio en la api.
        let parametros = {
            url: '/actualiza',
            datos: {...values, codigo_participante: codigo_participante, ...datosProductos, tipo_estudio: tipoEstudio}
        }
        dispatch(grabarUrl(parametros)).then((response) => {

            if (response.status) {
                navigate('/final')
            }
        });

    };

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        errores,
        clases
    } = useFormYup(enviar, validate, valoresDefecto);

    // esto es para el check de confirmación
    const inputCheck = (event) => {
        setValues({...values, [event.target.name]: event.target.checked});
    };

    const cambiaValor = (event) => {
        setValues({...values, [event.target.name]: parseInt(event.target.value)});
    };

    const navigate = useNavigate();


    return (
        <Container className="formulario__datos-soc" maxWidth={"md"}>
            <form noValidate>
                <div className="checkout-form">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                DATOS SOCIODEMOGRÁFICOS
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} >
                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'} id="sexo">Cual es su sexo ?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="1"
                                    value={values.sexo}
                                    onChange={cambiaValor}
                                    name="sexo">
                                    <FormControlLabel value={1} control={<Radio/>} label="Hombre"/>
                                    <FormControlLabel value={2} control={<Radio/>} label="Mujer"/>

                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="sexo"></RenderErrors>
                        </Grid>

                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'}>Edad (años cumplidos)</FormLabel>
                                <TextField
                                    fullWidth
                                    name="edad"
                                    value={values.edad}
                                    type="number"
                                    InputProps={{inputProps: {min: 16, max: 99}}}
                                    variant="outlined"
                                    size="small"
                                    onChange={cambiaValor}
                                />
                                <span className={'campo__notas'}>Por favor, escribe un número entre 16 y 99</span>
                            </FormControl>
                            <RenderErrors campo="edad"></RenderErrors>

                        </Grid>
                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'} id="nacionalidad">Nacionalidad</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="1"
                                    value={values.nacionalidad}
                                    onChange={cambiaValor}
                                    name="nacionalidad">
                                    <FormControlLabel value={1} control={<Radio/>} label="Española"/>
                                    <FormControlLabel value={2} control={<Radio/>} label="Otra"/>

                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="nacionalidad"></RenderErrors>
                        </Grid>


                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'} id="nacionalidad">Nivel educativo</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="1"
                                    value={values.nivel_estudios}
                                    onChange={cambiaValor}
                                    name="nivel_estudios">
                                    <FormControlLabel value={1} control={<Radio/>} label="Educacion primaria"/>
                                    <FormControlLabel value={2} control={<Radio/>}
                                                      label="Educación secundaria obligatoria"/>
                                    <FormControlLabel value={3} control={<Radio/>} label="Bachiller o FP"/>
                                    <FormControlLabel value={4} control={<Radio/>} label="Educación universitaria"/>


                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="nivel_estudios"></RenderErrors>
                        </Grid>

                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel className={'campo__label'}>Número de hijos</FormLabel>
                                    <TextField
                                        fullWidth
                                        name="numero_hijos"
                                        value={values.numero_hijos}
                                        type="number"
                                        InputProps={{inputProps: {min: 1, max: 15}}}
                                        variant="outlined"
                                        size="small"
                                        onChange={cambiaValor}
                                    />
                                    <span className={'campo__notas'}>Por favor, introduzca número de hijos</span>
                                </FormControl>
                                <RenderErrors campo="numero_hijos"></RenderErrors>
                            </Grid>

                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'}>Etapa académica de los hijos</FormLabel>

                                <RadioGroup
                                    value={values.etapa_academica}
                                    onChange={cambiaValor}
                                    name="etapa_academica">
                                    <FormControlLabel value={1} control={<Radio/>} label="Preescolar"/>
                                    <FormControlLabel value={2} control={<Radio/>} label="Primaria o secundaria"/>
                                    <FormControlLabel value={3} control={<Radio/>} label="Ambos"/>


                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="etapa_academica"></RenderErrors>
                        </Grid>

                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'}>Composición del hogar</FormLabel>

                                <RadioGroup
                                    value={values.composicion_hogar}
                                    onChange={cambiaValor}
                                    name="composicion_hogar">
                                    <FormControlLabel value={1} control={<Radio/>} label="Uniparental"/>
                                    <FormControlLabel value={2} control={<Radio/>} label="Biparental"/>


                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="composicion_hogar"></RenderErrors>
                        </Grid>


                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'}>Ingreso económico mensual familiar</FormLabel>
                                <RadioGroup
                                    defaultValue="0"
                                    value={values.ingresos_economicos}
                                    onChange={cambiaValor}
                                    name="ingresos_economicos">
                                    <FormControlLabel value={1} control={<Radio/>} label="<1000 euros>"/>
                                    <FormControlLabel value={2} control={<Radio/>} label="1000 - 2000"/>
                                    <FormControlLabel value={3} control={<Radio/>} label="2000 - 5000"/>
                                    <FormControlLabel value={4} control={<Radio/>} label="> 5000"/>


                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="ingresos_economicos"></RenderErrors>
                        </Grid>


                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'}>En la compra del día a día, ¿con qué frecuencia
                                    observas la información nutricional de los productos que te planteas
                                    adquirir?</FormLabel>
                                <RadioGroup
                                    defaultValue="1"
                                    value={values.consulta_informacion}
                                    onChange={cambiaValor}
                                    name="consulta_informacion">
                                    <FormControlLabel value={1} control={<Radio/>} label="Nunca"/>
                                    <FormControlLabel value={2} control={<Radio/>} label="Pocas veces"/>
                                    <FormControlLabel value={3} control={<Radio/>} label="Bastantes veces"/>
                                    <FormControlLabel value={4} control={<Radio/>} label="Casi siempre"/>
                                    <FormControlLabel value={5} control={<Radio/>} label="Siempre"/>


                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="consulta_informacion"></RenderErrors>
                        </Grid>

                        <Grid item xs={12} className={'formulario__pregunta'}>
                            <FormControl>
                                <FormLabel className={'campo__label'}>¿Conoces el significado del
                                    Nutri-Score?</FormLabel>
                                <RadioGroup
                                    defaultValue="1"
                                    value={values.conoces_nutriscore}
                                    onChange={cambiaValor}
                                    name="conoces_nutriscore">
                                    <FormControlLabel value={1} control={<Radio/>}
                                                      label="Ni idea, nunca he oído hablar de él."/>
                                    <FormControlLabel value={2} control={<Radio/>}
                                                      label="Me suena el nombre, pero no sé lo que es."/>
                                    <FormControlLabel value={3} control={<Radio/>}
                                                      label="Lo conozco, pero no sé interpretarlo."/>
                                    <FormControlLabel value={4} control={<Radio/>}
                                                      label="Lo conozco bien, sirve para ayudar en la elección de productos más saludables."/>
                                    <FormControlLabel value={5} control={<Radio/>}
                                                      label="o	Sí, lo conozco perfectamente, sé que utiliza un algoritmo en función de la composición nutricional para clasificar los alimentos en categorías de más a menos saludable."/>

                                </RadioGroup>
                            </FormControl>
                            <RenderErrors campo="conoces_nutriscore"></RenderErrors>
                        </Grid>
                    </Grid>


                    <div className="checkout-form-btn-section">
                        <Button variant="contained" color="primary" onClick={handleSubmit}
                                className={`btn-basket btn-enabled`}>
                            Terminar estudio
                        </Button>
                    </div>


                </div>
            </form>

        </Container>

    );
}

export default Formulario;
