import React, {useState, useEffect, useCallback} from 'react';

import {Container, Grid, Button} from '@modulos/ModulosMaterialUi';
import {scrollSpy, Events, animateScroll} from 'react-scroll'
import STATIC_API from './assets/tudecides.json'
import {isEmpty} from "lodash"
import './Productos.css';
import {useNavigate} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import {Radio, RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { setProductosStore} from '@store/reducers/tudecidesSlice';


const Productos = () => {
    const [productos, setProductos] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [verTablaNutricional, setVerTablaNutricional] = useState(false);

    const [numProducto, setNumProducto] = useState(1)
    const tipoEstudio = useSelector((state) => state.tudecides.tipo_estudio, shallowEqual);
    const productosStore = useSelector((state) => state.tudecides.productos, shallowEqual);
    const [producto, setProducto] = useState({})
    const [mensajeError,setMensajeError] = useState('')



    const navigate = useNavigate();
    const dispatch = useDispatch()


    const tiposEstudio = ['nada', 'nutriscore', 'alegacion', 'alegacion-nutriscore']
    let imagen = '';


    if (!isEmpty(producto)) {
        imagen = './productos_img/' + producto.imagenes[tiposEstudio[tipoEstudio - 1]]
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                setProductos(STATIC_API.productos)
            } catch (error) {

            }
            setLoading(false);
        };

        fetchData();

        scrollSpy.update();
        scrollToTop();
    }, []);

    useEffect(() => {
        setVerTablaNutricional(false)
        setProducto(productos[numProducto - 1])
    }, [productos, numProducto])


    const scrollToTop = useCallback(() => {
        animateScroll.scrollToTop();
    }, []);

    const siguienteProducto = () => {

        let error = false

        if (producto.cuestiones.cuestion_1 === 0 || producto.cuestiones.cuestion_2 === 0 || producto.cuestiones.cuestion_3 === 0  ) {
            setMensajeError('Falta una pregunta por contestar');
            error = true ;
        }
        if (error) return ;

        setMensajeError('')
        animateScroll.scrollToTop();
        productos[numProducto - 1] = producto;


        if (numProducto === productos.length) {
            dispatch(setProductosStore( productos));
            navigate('/formulario')
        }
        ;
        setNumProducto((value) => value + 1)
    }
    const anteriorProducto = () => {
        animateScroll.scrollToTop();

        productos[numProducto - 1] = producto;

        if (numProducto === 1) return;
        setNumProducto((value) => value - 1)
    }

    const cambiaValorCuestion1 = (event, value) => {
        let cuestiones = {...producto.cuestiones, cuestion_1: parseInt(value)}
        setProducto((prev) => ({...prev, cuestiones: cuestiones}));
    }

    const cambiaValorCuestion2 = (event, value) => {
        let cuestiones = {...producto.cuestiones, cuestion_2: parseInt(value)}
        setProducto((prev) => ({...prev, cuestiones: cuestiones}));
    }
    const cambiaValorCuestion3 = (event, value) => {
        let cuestiones = {...producto.cuestiones, cuestion_3: parseInt(value)}
        setProducto((prev) => ({...prev, cuestiones: cuestiones}));
    }

    return !isLoading ? (
        <>
            {!isEmpty(producto) &&
                <Container className="producto_ficha" maxWidth={"md"}>
                    <Grid container >
                        <Grid item xs={12} md={7}>

                            {imagen > '' &&
                                <img className={'producto__imagen'} src={imagen}/>
                            }

                        </Grid>

                        <Grid item className={'producto__datos'} xs={12} md={5}>
                            <Grid item xs={12}>
                                <span className={'producto__descripcion'}>{producto.descripcion}</span>
                            </Grid>

                            { (tipoEstudio === 3 || tipoEstudio === 4) &&
                            <Grid item xs={12}>
                                <span className={'alegacion__salud'}>{producto.alegacion_salud}</span>
                            </Grid>}
                            {producto.ingredientes > '' &&
                                <Grid item xs={12}>
                                    <span className={''}>Ingredientes:</span>
                                    <span className={'producto__ingredientes'}>{producto.ingredientes}</span>
                                </Grid>}
                            { (tipoEstudio === 2 || tipoEstudio === 4) &&
                            <div className={'sello__nutriscore'}>
                                {producto.nutriscore === 'A' && <img alt={"NSA"} src={'./sellos_nutriscore/NS_A.png'}/>}
                                {producto.nutriscore === 'B' && <img alt={"NSB"} src={'./sellos_nutriscore/NS_B.png'}/>}
                                {producto.nutriscore === 'C' && <img alt={"NSC"} src={'./sellos_nutriscore/NS_C.png'}/>}
                                {producto.nutriscore === 'D' && <img alt={"NSD"} src={'./sellos_nutriscore/NS_D.png'}/>}
                                {producto.nutriscore === 'E' && <img alt={"NSE"} src={'./sellos_nutriscore/NS_E.png'}/>}
                            </div>}

                            {/*  <div className={'logos__chilenos'}>
                                    { producto.sellos_chilenos.alto_calorias === 1 && <img className='sello__chileno' src={'./sellos_americanos/altoEnCalorias.png'} /> }
                                    { producto.sellos_chilenos.alto_azucares === 1 && <img className='sello__chileno' src={'./sellos_americanos/altoAzucares.png'} /> }
                                    { producto.sellos_chilenos.alto_sodio === 1 && <img className='sello__chileno' src={'./sellos_americanos/AltoSodio.png'} /> }
                                    { producto.sellos_chilenos.alto_grasas === 1 && <img className='sello__chileno' src={'./sellos_americanos/AltoGrasasSaturadas.png'} /> }
                                </div>
*/}
                            <Button variant="contained" className='ml-2' color="primary" onClick={() => {
                                setVerTablaNutricional(!verTablaNutricional)
                            }}>Ver tabla nutricional</Button>

                            {verTablaNutricional &&
                                <div className={'tabla__valores-nutricionales'}>
                                    <span className={'tabla__titulo'}>INFORMACIÓN NUTRICIONAL	Por 100g</span>
                                    {producto.informacion_nutricional.map((valor, index) => {
                                        return (
                                            <div key={index} className={'valor_nutricional'}>
                                                <span className={'descripcion'}>{valor.descripcion}</span>
                                                <span className={'valor'}>{valor.valor}</span>
                                            </div>)
                                    })}

                                </div>}

                        </Grid>

                    </Grid>
                    <Grid className={'productos__cuestionario'} container >
                        <div className={'producto__preguntas'}>

                            <span className={'producto__titulo-preguntas'}>Imagine que está pensando en comprar un producto de desayuno para sus hijos.</span>
                            <span className={'producto__pregunta'}>¿Compraría este producto?<br/> <span style={{fontSize:"14px"}}> Responda de 1 (No, de ninguna manera) a 7 (Sí, sin dudarlo).</span></span>
                            <Grid item xs={12}>
                                <FormControl>
                                    <RadioGroup style={{display: 'flex', flexDirection: 'row'}}
                                                defaultValue={0}
                                                value={producto.cuestiones.cuestion_1}
                                                onChange={cambiaValorCuestion1}
                                                name="consulta_informacion">
                                        <FormControlLabel value={1} control={<Radio/>} label="1"/>
                                        <FormControlLabel value={2} control={<Radio/>} label="2"/>
                                        <FormControlLabel value={3} control={<Radio/>} label="3"/>
                                        <FormControlLabel value={4} control={<Radio/>} label="4"/>
                                        <FormControlLabel value={5} control={<Radio/>} label="5"/>
                                        <FormControlLabel value={6} control={<Radio/>} label="6"/>
                                        <FormControlLabel value={7} control={<Radio/>} label="7"/>

                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                        </div>

                        <div className={'producto__preguntas'}>
                            {/*       <span className={'numero__pregunta'}>1</span>*/}
                            <span className={'producto__pregunta'}>¿Piensa que este producto es saludable?<br/>Responda de 1 (No, nada saludable) a 7 (Sí, muy saludable).</span>
                            <Grid item xs={12}>
                                <FormControl>
                                    <RadioGroup style={{display: 'flex', flexDirection: 'row'}}
                                                defaultValue={0}
                                                value={producto.cuestiones.cuestion_2}
                                                onChange={cambiaValorCuestion2}
                                                name="consulta_informacion">
                                        <FormControlLabel value={1} control={<Radio/>} label="1"/>
                                        <FormControlLabel value={2} control={<Radio/>} label="2"/>
                                        <FormControlLabel value={3} control={<Radio/>} label="3"/>
                                        <FormControlLabel value={4} control={<Radio/>} label="4"/>
                                        <FormControlLabel value={5} control={<Radio/>} label="5"/>
                                        <FormControlLabel value={6} control={<Radio/>} label="6"/>
                                        <FormControlLabel value={7} control={<Radio/>} label="7"/>

                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                        </div>
                        <div className={'producto__preguntas'}>
                            {/*       <span className={'numero__pregunta'}>1</span>*/}
                            <span className={'producto__pregunta'}>¿Con qué frecuencia considera que sería aconsejable que sus hijos consumieran este producto? <br/>Responda de 1 (Nunca) a 7 (A diario.)</span>
                            <Grid item xs={12}>
                                <FormControl>
                                    <RadioGroup style={{display: 'flex', flexDirection: 'row'}}
                                                defaultValue={0}
                                                value={producto.cuestiones.cuestion_3}
                                                onChange={cambiaValorCuestion3}
                                                name="consulta_informacion">
                                        <FormControlLabel value={1} control={<Radio/>} label="1"/>
                                        <FormControlLabel value={2} control={<Radio/>} label="2"/>
                                        <FormControlLabel value={3} control={<Radio/>} label="3"/>
                                        <FormControlLabel value={4} control={<Radio/>} label="4"/>
                                        <FormControlLabel value={5} control={<Radio/>} label="5"/>
                                        <FormControlLabel value={6} control={<Radio/>} label="6"/>
                                        <FormControlLabel value={7} control={<Radio/>} label="7"/>

                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                        </div>

                    </Grid>
                    <Grid className={'producto__botones'} item xs={12}>
                        <span className={'mensaje__error'}>{mensajeError}</span>
                        {numProducto > 1 &&
                            <Button variant="contained" onClick={() => {
                                anteriorProducto()
                            }}>Anterior</Button>}


                        <Button variant="contained" className='ml-2' color="primary" onClick={() => {
                            siguienteProducto()
                        }}>Siguiente</Button>


                    </Grid>
                </Container>
            }


        </>
    ) : (
        'Cargando ...'
    );
}

export default Productos;
