import React, {useEffect, useState, useCallback,useRef} from 'react';
import { CssBaseline} from '@modulos/ModulosMaterialUi';
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import styled from "styled-components"

import {apiEvora} from "../Utils";

import "./Header.css";
import Container from "@material-ui/core/Container";

const BotonEvo = styled.button`
	border-radius: 5px;
	color: black;
	padding: 10px 15px;
	outline: none;
	border: none;
	cursor: pointer;
	border: 1px solid #eee;
	margin: 15px;
	&:hover {
        background: palevioletred;
    }
	`

const Header = () => {



    const cart = useSelector((state) => state.cart, shallowEqual)
    const user = useSelector((state) => state.user, shallowEqual)

    return (
        <div className="evoratec__header">
                <CssBaseline/>
                <div className="cabecera-reservas">
                    <Container  maxWidth={"md"}>

                            <a className="navbar-brand" title="Logo Proto" rel="home">
                                <img src="https://tucesta2.hestiaxxi.com/wp-content/uploads/2021/11/logo500-e1636455394425.png"
                            alt="Hestia"/>
                            </a>
                            <h2>TU DECIDES</h2>


                        </Container>
                </div>

        </div>
);
}
export default Header;
